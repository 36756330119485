export const getRightWordsForm = (
    value,
    arrayOfWords
) => {
    return arrayOfWords[
        value % 100 > 4 && value % 100 < 20
            ? 2
            : [2, 0, 1, 1, 1, 2][value % 10 < 5 ? value % 10 : 5]
        ];
};
